// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, CssBaseline, List, ListItem, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AuthContext from './context/AuthContext';
import { supabase } from './supabaseClient';
import axios from 'axios';
import useAuthToken from './hooks/useAuthToken';
import { Typography } from '@mui/material';

// Import the new components
import AppHeader from './components/AppHeader';
import SidebarDrawer from './components/SidebarDrawer';
import MainContent from './components/MainContent';

// Import other components
import AuthPage from './components/AuthPage';
import PaymentConfirmation from './components/PaymentConfirmation';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';

// Theme setup
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#00bcd4' },
    secondary: { main: '#ff4081' },
    background: { default: '#121212', paper: '#1e1e1e' },
    text: { primary: '#fff', secondary: '#aaa' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

// Define the width of the Drawer
const drawerWidth = 240;

const App = () => {
  // State variables
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(null); // Track open main drawers
  const [openSubDrawer, setOpenSubDrawer] = useState(null); // Track open sub-drawers like EchoBach Devices
  const [anchorEl, setAnchorEl] = useState(null); // For settings menu
  const [mobileOpen, setMobileOpen] = useState(false); // Mobile drawer toggle state
  const [selectedDevice, setSelectedDevice] = useState(null); // Selected Bach device
  const [selectedAgent, setSelectedAgent] = useState(null); // Selected agent
  const [selectedWorkflow, setSelectedWorkflow] = useState(null); // Selected workflow
  const [selectedMemoryGraph, setSelectedMemoryGraph] = useState(null); // Selected memoryGraph
  const [selectedSection, setSelectedSection] = useState(null); // Selected main section (e.g., Agents, Account)
  const [assistants, setAssistants] = useState([]);
  const [isLoadingAssistants, setIsLoadingAssistants] = useState(false);
  const [bachs, setBachs] = useState([]);
  const token = useAuthToken(); // Retrieve token here

  const clearSelectedDevice = () => setSelectedDevice(null);
  const clearSelectedAgent = () => setSelectedAgent(null);

  const [ragAgents, setRagAgents] = useState([]);
  const [openRagAgentsDrawer, setOpenRagAgentsDrawer] = useState(false);

  useEffect(() => {
    if (token) {
      axios.get(`${API_BASE_URL}/assistant/list_rag_agents`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then((res) => {
        setRagAgents(res.data.agents);
      }).catch(console.error);
    }
  }, [token]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch devices
  const fetchBachs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bach_registry/get-bachs/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBachs(response.data);
    } catch (error) {
      console.error('Error fetching bachs:', error);
    }
  };

  // Fetch assistants 
  const fetchAssistants = async () => {
    setIsLoadingAssistants(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/assistant/list_assistants_with_tools/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAssistants(response.data);
    } catch (error) {
      console.error('Error fetching assistants:', error);
    } finally {
      setIsLoadingAssistants(false);
    }
  };

  
  useEffect(() => {
    if (token) {
      fetchAssistants(); 
      fetchBachs();
    }
  }, [token]); 

  // Check if the screen size is small
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Authentication state management
  useEffect(() => {
    const checkAuthState = async () => {
      const { data } = await supabase.auth.getSession();
      setUser(data.session?.user || null);
      setLoading(false);
      console.log('Auth State: User set', data.session?.user);
    };

    checkAuthState();
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
      setLoading(false);
      console.log('Auth State Change:', event, session?.user);
    });

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  // // Toggle main drawer (Bachs, Agents, etc.)
  // const toggleDrawerItem = (drawerName) => {
  //   if (openDrawer === drawerName) {
  //     setOpenDrawer(null);
  //   } else {
  //     setOpenDrawer(drawerName);
  //     setOpenSubDrawer(null); // Close any open sub-drawer when switching main drawer
  //   }
  //   console.log(`Toggled Drawer: ${drawerName}`);

  //   // Set the selected section based on drawerName
  //   if (drawerName === 'Agents') {
  //     setSelectedSection('Agents');
  //     // Reset other selections
  //     setSelectedDevice(null);
  //     setSelectedAgent(null);
  //     setSelectedMemoryGraph(null);
  //     setSelectedWorkflow(null);
  //   } else if (
  //     drawerName === 'Bachs' ||
  //     drawerName === 'Memory Graph' ||
  //     drawerName === 'Agentic Workflows'
  //   ) {
  //     setSelectedSection(null);
  //   }
  // };

  // Toggle main drawer (Bachs, Agents, etc.)
  const toggleDrawerItem = (drawerName) => {
    if (openDrawer === drawerName) {
      setOpenDrawer(null);
    } else {
      setOpenDrawer(drawerName);
      setOpenSubDrawer(null); // Close any open sub-drawer when switching main drawer
    }
    console.log(`Toggled Drawer: ${drawerName}`);

    // Set the selected section based on drawerName
    if (drawerName === 'Agents') {
      setSelectedSection('Agents');
      // Reset other selections
      setSelectedDevice(null);
      setSelectedAgent(null);
      setSelectedMemoryGraph(null);
      setSelectedWorkflow(null);
    } else if (drawerName === 'Bachs' || drawerName === 'Memory Graph' || drawerName === 'Agentic Workflows') {
      setSelectedSection(null);
    } else if (drawerName === 'RAG Documents') {
      setSelectedSection('RAG Documents');
      setSelectedDevice(null);
      setSelectedAgent(null);
      setSelectedWorkflow(null);
      setSelectedMemoryGraph(null);
    }
  };


  // Toggle sub-drawer for devices
  const toggleSubDrawerItem = (subDrawerName) => {
    if (openSubDrawer === subDrawerName) {
      setOpenSubDrawer(null);
    } else {
      setOpenSubDrawer(subDrawerName);
    }
    console.log(`Toggled Sub-Drawer: ${subDrawerName}`);
  };

  // Handle Settings menu
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log('Settings menu opened');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    console.log('Settings menu closed');
  };

  // Handle Logout
  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    handleMenuClose();
    console.log('User logged out');
  };

  // Handle Account Click
  const handleAccountClick = () => {
    setSelectedSection('Account');
    setSelectedDevice(null);
    setSelectedAgent(null);
    setSelectedWorkflow(null);
    handleMenuClose();
  };

  // Selection handlers
  const handleDeviceSelection = (device) => {
    console.log('Selected device:', device);
    setSelectedDevice(device);
    setSelectedAgent(null); // Reset other selections
    setSelectedWorkflow(null);
    setSelectedMemoryGraph(null);
    setSelectedSection(null);
  };

  const handleAgentSelection = (agent) => {
    console.log('Selected agent:', agent);
    setSelectedAgent(agent);
    setSelectedDevice(null); // Reset other selections
    setSelectedWorkflow(null);
    setSelectedMemoryGraph(null);
    // setSelectedSection(null);
  };

  const handleWorkflowSelection = (workflow) => {
    console.log('Selected workflow:', workflow);
    setSelectedWorkflow(workflow);
    setSelectedDevice(null); // Reset other selections
    setSelectedAgent(null);
    setSelectedMemoryGraph(null);
    setSelectedSection(null);
  };

  const handleMemoryGraphSelection = (memoryGraph) => {
    console.log('Selected memoryGraph:', memoryGraph);
    setSelectedMemoryGraph(memoryGraph);
    setSelectedDevice(null); // Reset other selections
    setSelectedAgent(null);
    setSelectedSection(null);
  };

  // Define styles for active items
  const getActiveStyle = (isActive) => ({
    color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
    fontWeight: isActive ? 'bold' : 'normal',
  });

  // Handle mobile drawer toggle
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Function to refresh bachs after updates
  const refreshBachs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bach_registry/get-bachs/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBachs(response.data);
    } catch (error) {
      console.error('Error refreshing bachs:', error);
    }
  };

  // Drawer Content

  const drawerContent = (
    <List>
      {/* Bachs Drawer */}
      <ListItem
        button
        onClick={() => toggleDrawerItem('Bachs')}
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        <ListItemText primary="Bachs" />
        {openDrawer === 'Bachs' ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDrawer === 'Bachs'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          {/* Web Devices */}
          <ListItem
            button
            onClick={() => toggleSubDrawerItem("Web")}
            sx={{ pl: 4, color: 'text.secondary' }}
          >
            <ListItemText primary="Web" />
            {openSubDrawer === "Web" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubDrawer === "Web"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {bachs.filter(bach => bach.device_type === 'web').map((bach) => (
                <ListItem
                  button
                  key={bach.device_id}
                  onClick={() => handleDeviceSelection(bach)}
                  sx={{ pl: 6, ...getActiveStyle(selectedDevice?.device_id === bach.device_id) }}
                >
                  <ListItemText primary={bach.alias || bach.device_id} />
                </ListItem>
              ))}
            </List>
          </Collapse>

          {/* iOS Devices */}
          <ListItem
            button
            onClick={() => toggleSubDrawerItem("iOS Devices")}
            sx={{ pl: 4, color: 'text.secondary' }}
          >
            <ListItemText primary="iOS Devices" />
            {openSubDrawer === "iOS Devices" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubDrawer === "iOS Devices"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {bachs.filter(bach => bach.device_type === 'iphone').map((bach) => (
                <ListItem
                  button
                  key={bach.device_id}
                  onClick={() => handleDeviceSelection(bach)}
                  sx={{ pl: 6, ...getActiveStyle(selectedDevice?.device_id === bach.device_id) }}
                >
                  <ListItemText primary={bach.alias || bach.device_id} />
                </ListItem>
              ))}
            </List>
          </Collapse>

          {/* EchoBach Devices */}
          <ListItem
            button
            onClick={() => toggleSubDrawerItem("EchoBach Devices")}
            sx={{ pl: 4, color: 'text.secondary' }}
          >
            <ListItemText primary="EchoBach Devices" />
            {openSubDrawer === "EchoBach Devices" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubDrawer === "EchoBach Devices"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {bachs.filter(bach => bach.device_type === 'echobach_alpha').map((bach) => (
                <ListItem
                  button
                  key={bach.device_id}
                  onClick={() => handleDeviceSelection(bach)}
                  sx={{ pl: 6, ...getActiveStyle(selectedDevice?.device_id === bach.device_id) }}
                >
                  <ListItemText primary={bach.alias || bach.device_id} />
                </ListItem>
              ))}
            </List>
          </Collapse>

          {/* Phone Numbers */}
          <ListItem
            button
            // onClick={() => toggleSubDrawerItem("Phone Numbers")}
            onClick={() => {
              toggleSubDrawerItem("Phone Numbers");
              // If we're opening the drawer, set selectedSection to "Phone Numbers"
              // If closing, clear it.
              setSelectedSection(openSubDrawer === "Phone Numbers" ? null : "Phone Numbers");
              // Also clear selectedDevice when switching sections
              clearSelectedDevice();
            }}
            sx={{ pl: 4, color: 'text.secondary' }}
          >
            <ListItemText primary="Phone Numbers" />
            {openSubDrawer === "Phone Numbers" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubDrawer === "Phone Numbers"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {bachs.filter(bach => bach.device_type === 'twilio_phone').map((bach) => (
                <ListItem
                  button
                  key={bach.device_id}
                  onClick={() => handleDeviceSelection(bach)}
                  sx={{ pl: 6, ...getActiveStyle(selectedDevice?.device_id === bach.device_id) }}
                >
                  <ListItemText primary={bach.alias || bach.device_id} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </Collapse>

      {/* Agents Drawer */}
      <ListItem
        button
        onClick={() => toggleDrawerItem("Agents")}
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        <ListItemText primary="Agents" />
        {openDrawer === "Agents" ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDrawer === "Agents"} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {assistants.map((assistant) => (
            <ListItem
              button
              key={assistant.assistant_id}
              onClick={() => handleAgentSelection(assistant)}
              sx={{ pl: 4, ...getActiveStyle(selectedAgent?.assistant_id === assistant.assistant_id) }}
            >
              <ListItemText primary={assistant.assistant_name || 'Unnamed Assistant'} />
            </ListItem>
          ))}
        </List>
      </Collapse>

      {/* RAG Documents Drawer */}
      <ListItem button onClick={() => toggleDrawerItem("RAG Documents")}>
        <ListItemText primary="RAG Documents" />
        {openDrawer === "RAG Documents" ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDrawer === "RAG Documents"} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {ragAgents.map((agent) => (
            <ListItem 
              button
              key={agent.assistant_id}
              sx={{ pl: 4 }}
              onClick={() => {
                setSelectedAgent(agent);
                setSelectedSection('RAG Documents');
              }}
            >
              <ListItemText primary={agent.assistant_name || agent.assistant_id} />
            </ListItem>
          ))}
        </List>
      </Collapse>


      {/* Memory Graph Drawer */}
      <ListItem
        button
        onClick={() => toggleDrawerItem("Memory Graph")}
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        <ListItemText primary="Memory Graph" />
        {openDrawer === "Memory Graph" ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDrawer === "Memory Graph"} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            onClick={() => handleMemoryGraphSelection("Ellie")}
            sx={{ pl: 4, ...getActiveStyle(selectedMemoryGraph === "Ellie") }}
          >
            <ListItemText primary="Ellie" />
          </ListItem>
        </List>
      </Collapse>

      {/* Agentic Workflows Drawer */}
      <ListItem
        button
        onClick={() => toggleDrawerItem("Agentic Workflows")}
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        <ListItemText primary="Agentic Workflows" />
        {openDrawer === "Agentic Workflows" ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDrawer === "Agentic Workflows"} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            onClick={() => handleWorkflowSelection("Book an Appointment")}
            sx={{ pl: 4, ...getActiveStyle(selectedWorkflow === "Book an Appointment") }}
          >
            <ListItemText primary="Book an Appointment" />
          </ListItem>
        </List>
      </Collapse>

      {/* Integrations Drawer */}
      <ListItem
        button
        onClick={() => toggleDrawerItem("Integrations")}
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        <ListItemText primary="Integrations" />
        {openDrawer === "Integrations" ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDrawer === "Integrations"} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            onClick={() => handleWorkflowSelection("Notion")}
            sx={{ pl: 4, ...getActiveStyle(selectedWorkflow === "Notion") }}
          >
            <ListItemText primary="Notion" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleWorkflowSelection("Zoom")}
            sx={{ pl: 4, ...getActiveStyle(selectedWorkflow === "Zoom") }}
          >
            <ListItemText primary="Zoom" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );


  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ user, setUser }}>
        <Router>
          <CssBaseline />
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <Routes>
              {user ? (
                <>
                  {/* Authenticated Routes */}
                  <Route
                    path="/"
                    element={
                      <Box sx={{ display: 'flex', marginTop: isMobile ? 8 : 0 }}>
                        {/* Sidebar Drawer */}
                        <SidebarDrawer
                          drawerWidth={drawerWidth}
                          isMobile={isMobile}
                          mobileOpen={mobileOpen}
                          handleDrawerToggle={handleDrawerToggle}
                          drawerContent={drawerContent}
                        />
                        {/* Main Content Area */}
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                          <AppHeader
                            isMobile={isMobile}
                            handleDrawerToggle={handleDrawerToggle}
                            anchorEl={anchorEl}
                            handleMenuClick={handleMenuClick}
                            handleMenuClose={handleMenuClose}
                            handleAccountClick={handleAccountClick}
                            handleLogout={handleLogout}
                          />
                          <MainContent
                            loading={loading}
                            user={user}
                            selectedSection={selectedSection}
                            selectedDevice={selectedDevice}
                            selectedAgent={selectedAgent}
                            selectedWorkflow={selectedWorkflow}
                            selectedMemoryGraph={selectedMemoryGraph}
                            assistants={assistants}
                            isLoadingAssistants={isLoadingAssistants}
                            refreshBachs={refreshBachs}
                            fetchBachs={fetchBachs} // Pass as a callback
                            fetchAssistants={fetchAssistants} // Pass as a callback
                            clearSelectedDevice={clearSelectedDevice}
                            clearSelectedAgent={clearSelectedAgent}
                          />
                        </Box>
                      </Box>
                    }
                  />
                  {/* Payment Confirmation Route */}
                  <Route
                    path="/payment_method_status"
                    element={
                      <Box sx={{ display: 'flex', marginTop: isMobile ? 8 : 0 }}>
                        {/* You can include Sidebar and Header if needed */}
                        <PaymentConfirmation />
                      </Box>
                    }
                  />
                  {/* Catch-all Route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
               
                </>
              ) : (
                <>
                  {/* Unauthenticated Routes */}
                  <Route path="/" element={<AuthPage />} />
                  {/* Catch-all Route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </>
              )}
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          )}
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;
