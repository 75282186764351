// src/components/MainContent.js
import React from 'react';
import { Box, Typography, Card, CardHeader, Avatar, CardContent } from '@mui/material';
import Talk from './Talk';
import AccountPage from './AccountPage';
import CreateNewAgent from './CreateNewAgent';
import BachPage from './BachPage';
import AgentPage from './AgentPage';
import MemoryGraph from './MemoryGraph';
import PhoneNumbersPage from './PhoneNumbersPage';
import RAGDocumentsPage from './RAGDocumentsPage';
import RAGAgentDocsPage from './RAGAgentDocsPage';

const MainContent = ({
  loading,
  user,
  selectedSection,
  selectedDevice,
  selectedAgent,
  selectedWorkflow,
  selectedMemoryGraph,
  assistants,
  isLoadingAssistants,
  refreshBachs,
  fetchAssistants,
  clearSelectedDevice,
  clearSelectedAgent
}) => {
  return (
    <>
      {/* Talk component */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Talk />
      </Box>

      {/* Main Content Rendering */}
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : user ? (
        <>
          {/* Render content based on selectedSection */}
          {selectedSection === 'Account' && <AccountPage />}

          {/* Only show CreateNewAgent if we're in Agents section and no agent is selected */}
          {selectedSection === 'Agents' && !selectedAgent && (
            <CreateNewAgent onAgentCreated={fetchAssistants} />
          )}

          {/* Only show AgentPage if we're in Agents section and an agent is selected */}
          {selectedSection === 'Agents' && selectedAgent && (
            <AgentPage
              agent={selectedAgent}
              fetchAssistants={fetchAssistants}
              clearSelectedAgent={clearSelectedAgent}
            />
          )}

          {/* If selectedDevice is a string "Phone Numbers", show the PhoneNumbersPage */}
          {selectedSection === 'Phone Numbers' && !selectedDevice && (
            <PhoneNumbersPage refreshBachs={refreshBachs} />
          )}

          {/* Render DeviceTelemetry and InteractionHistory for EchoBach Devices */}
          {selectedDevice && typeof selectedDevice === 'object' && (
            <BachPage
              device={selectedDevice}
              assistants={assistants}
              isLoadingAssistants={isLoadingAssistants}
              refreshBachs={refreshBachs}
              clearSelectedDevice={clearSelectedDevice}
            />
          )}

          {/* If section is RAG Documents and an agent is selected, show RAGAgentDocsPage */}
          {selectedSection === 'RAG Documents' && selectedAgent && (
            <RAGAgentDocsPage
              assistantId={selectedAgent.assistant_id}
              onBack={() => {
                // On back, maybe clear selectedAgent or reset to RAG Documents page
                clearSelectedAgent();
              }}
            />
          )}

          {/* If section is RAG Documents and no agent is selected, show the main RAGDocumentsPage */}
          {selectedSection === 'RAG Documents' && !selectedAgent && (
            <RAGDocumentsPage />
          )}

          {/* Header Card for Selected Workflow */}
          {selectedWorkflow && (
            <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'secondary.main' }}>
                    {selectedWorkflow.charAt(0)}
                  </Avatar>
                }
                title={
                  <Typography variant="h5" component="div">
                    {selectedWorkflow} Workflow
                  </Typography>
                }
                subheader={
                  <Typography variant="body2" color="text.secondary">
                    Configure and monitor the {selectedWorkflow} workflow.
                  </Typography>
                }
              />
              <CardContent>
                <Typography>This is where workflow-specific information will go.</Typography>
              </CardContent>
            </Card>
          )}

          {/* Render MemoryGraph for selected device */}
          {selectedMemoryGraph && <MemoryGraph device={selectedMemoryGraph} />}

          {/* Placeholder when nothing selected */}
          {!selectedDevice &&
            !selectedAgent &&
            !selectedWorkflow &&
            !selectedSection &&
            !selectedMemoryGraph && (
              <Card sx={{ backgroundColor: '#1e1e1e', p: 2, boxShadow: 3 }}>
                <Typography variant="h6">Welcome to EchoBach Dashboard</Typography>
                <Typography>Select an item from the menu to view its details.</Typography>
              </Card>
            )}
        </>
      ) : (
        <Typography variant="h6">Please log in to access the dashboard.</Typography>
      )}
    </>
  );
};

export default MainContent;



